import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import API from '../data/api';

const SocketContext = createContext();
export const useSocket = () => useContext(SocketContext);
let socket = null
export const SocketProvider = ({ children }) => {
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        if (token) {
            const newSocket = io(API.SOCKET_URL + '?token=' + token, { path: "/jyedu-news", transports: ["websocket"], withCredentials: true }); // Socket.io服务器地址
            socket = newSocket
            // 监听连接打开事件
            // newSocket.onopen = () => {
            //     // 发送 JWT 令牌到后端进行验证
            //     socket.send(JSON.stringify({ token: `Bearer ${token}` }));
            // };
            // return () => newSocket.close();
        }
    }, [token]);
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export function getSocket() {
    return socket;
}

export const useSocketEvent = (eventName, handler) => {
    useEffect(() => {
        if (socket) {
            socket.on(eventName, handler);
            return () => {
                socket.off(eventName, handler);
            };
        }
    }, [socket, eventName, handler]);
};