const local = {
	WEB_URL: 'http://localhost:5000/',
	SOCKET_URL: 'http://localhost:5000/'
};

const dev = {
	WEB_URL: 'http://192.168.1.111/',
	SOCKET_URL: 'http://192.168.1.111/'
};

const prod = {
	WEB_URL: 'http://code.jingyaedu.com/',
	SOCKET_URL: 'http://39.174.68.5:5000/',

};
const STAGE = process.env.REACT_APP_STAGE
const env = STAGE === 'production'
	? prod
	: STAGE === 'dev' ? dev : local;

export default env;
