import routers from './router/MainRouter';

import { useAuthRouters } from "react-router-auth-plus";
import NoAuth from "./pages/404/NoPermission";

const App = (index) => {
  let data = sessionStorage.getItem('role');
  return useAuthRouters({
    // 传入当前用户的权限
    auth: data || [],
    // 若进入没权限的页面，显示 403 页面
    noAuthElement: () => <NoAuth />,
    routers: index.value == 1 ? [] : routers,
  });

}

export default App;
