import { Navigate } from 'react-router';
import AuthRouterObject from 'react-router-auth-plus';
import { lazy } from 'react';

const MainLayout = lazy(() => import('../layout/MainLayout'));
const MiniLayout = lazy(() => import('../layout/AllLayout'));
const AuthRouter = lazy(() => import('./PrivateRoute'));
const Login = lazy(() => import('../pages/login/Login'));
const Contest = lazy(() => import('../pages/contest/Contest'));
const MyContest = lazy(() => import('../pages/contest/MyContest'));
const FAQs = lazy(() => import('../pages/overview/FAQs'));
const Rank = lazy(() => import('../pages/overview/Rank'));
const SubmitHistory = lazy(() => import('../pages/overview/SubmitHistory'));
const SummaryToday = lazy(() => import('../pages/overview/SummaryToday'));
const KnowledgeShare = lazy(() => import('../pages/overview/KnowledgeShare'));
const KnowledgeShareDetail = lazy(() => import('../pages/overview/KnowledgeShareDetail'));
const Topics = lazy(() => import('../pages/contest/Topics'));
const NoPermission = lazy(() => import('../pages/404/NoPermission'));
const TopicDetail = lazy(() => import('../pages/contest/TopicDetail'));
const RuntimeInfo = lazy(() => import('../pages/overview/RuntimeInfo'));
const ResetPassword = lazy(() => import('../pages/login/ResetPassword'));
const ContestRank = lazy(() => import('../pages/contest/ContestRank'));
const ShowSourceCode = lazy(() => import('../pages/contest/ShowSourceCode'));
const OnlineDiscuss = lazy(() => import('../pages/contest/OnlineDiscuss'));
const ClassMgmt = lazy(() => import('../pages/admin/ClassMgmt'));
const SchoolMgmt = lazy(() => import('../pages/admin/SchoolMgmt'));
const UserList = lazy(() => import('../pages/admin/UserList'));
const AddUserProfile = lazy(() => import('../pages/admin/AddUserProfile'));
const EditUserProfile = lazy(() => import('../pages/admin/EditUserProfile'));
const PortraitMgmt = lazy(() => import('../pages/admin/PortraitMgmt'));
const TopicsMgmt = lazy(() => import('../pages/admin/TopicsMgmt'));
const TopicSetMgmt = lazy(() => import('../pages/admin/TopicSetMgmt'));
const MyContestMgmt = lazy(() => import('../pages/admin/MyContestMgmt'));
const TopicAdd = lazy(() => import('../pages/admin/TopicAdd'));
const TopicEdit = lazy(() => import('../pages/admin/TopicEdit'));
const CreateContests = lazy(() => import('../pages/admin/CreateContests'));
const EditContests = lazy(() => import('../pages/admin/EditContests'));
const ArticleMgmt = lazy(() => import('../pages/admin/ArticleMgmt'));
const CourseMgmt = lazy(() => import('../pages/admin/CourseMgmt'));
const ReplyMgmt = lazy(() => import('../pages/admin/ReplyMgmt'));
const StudentPerformaceList = lazy(() => import('../pages/admin/StudentPerformaceList'));
const MessageMgmt = lazy(() => import('../pages/admin/MessageMgmt'));
const LoginList = lazy(() => import('../pages/admin/LoginList'));
const UserInfo = lazy(() => import('../pages/admin/UserInfo'));
const CheckInMgmt = lazy(() => import('../pages/admin/CheckInMgmt'));
const CheckInList = lazy(() => import('../pages/admin/CheckInList'));
const UserSubmitDetail = lazy(() => import('../pages/overview/UserSubmitDetail'));
const FavoritesList = lazy(() => import('../pages/overview/FavoritesList'));
const StudentList = lazy(() => import('../pages/admin/StudentList'));
const Snapshot = lazy(() => import('../pages/admin/Snapshot'));
const SnapshotDetail = lazy(() => import('../pages/overview/SnapshotDetail'));
const ActivateVIP = lazy(() => import('../pages/vip/ActivateVIP'));
const BatchImportUser = lazy(() => import('../pages/admin/BatchImportUser'));
const UserContestStatus = lazy(() => import('../pages/overview/UserContestStatus'));
const JudgeMgmt = lazy(() => import('../pages/admin/JudgeMgmt'));
const CreateChoiceTopics = lazy(() => import('../pages/admin/CreateChoiceTopics'));
const ChoiceTopicsMgmt = lazy(() => import('../pages/admin/ChoiceTopicsMgmt'));
const EditChoiceTopics = lazy(() => import('../pages/admin/EditChoiceTopics'));
const EvaluationCenter = lazy(() => import('../pages/contest/EvaluationCenter'));
const Evaluation = lazy(() => import('../pages/contest/Evaluation'));
const Workplace = lazy(() => import('../pages/contest/Workplace'));
const EvaluationMgmt = lazy(() => import('../pages/contest/EvaluationMgmt'));
const ScratchSubmit = lazy(() => import('../pages/contest/ScratchSubmit'));
const PlatformMgmt = lazy(() => import('../pages/admin/PlatformMgmt'));
const CreateCompletion = lazy(() => import('../pages/admin/CreateCompletion'));
const EditCompletion = lazy(() => import('../pages/admin/EditCompletion'));
const EvaluationSummary = lazy(() => import('../pages/contest/EvaluationSummary'));
const EvaluationDetail = lazy(() => import('../pages/contest/EvaluationDetail'));
const ActivityEntrance = lazy(() => import('../pages/overview/ActivityEntrance'));
const ActivityEntranceDetail = lazy(() => import('../pages/overview/ActivityEntranceDetail'));
const ProductMgmt = lazy(() => import('../pages/admin/ProductMgmt'));
const BlocklyTopicAdd = lazy(() => import('../pages/admin/BlocklyTopicAdd'));
const BlocklyTopicEdit = lazy(() => import('../pages/admin/BlocklyTopicEdit'));
const ScratchTopicDetail = lazy(() => import('../pages/contest/ScratchTopicDetail'));

const MyCourse = lazy(() => import('../pages/contest/MyCourse'));
const CreateCourse = lazy(() => import('../pages/admin/CreateCourse'));
const EditCourse = lazy(() => import('../pages/admin/EditCourse'));

const LessonPlan = lazy(() => import('../pages/contest/LessonPlan'));

const Register = lazy(() => import('../pages/login/Register'));
const PdfTopicDetail = lazy(() => import('../components/PdfTopicDetail'));
const ExportTopicList = lazy(() => import('../pages/admin/ExportTopicList'));
const MemberAnswers = lazy(() => import('../pages/contest/MemberAnswers'));
const FlowEdit = lazy(() => import('../pages/codeEditor/FlowEdit'));
const SubmitHistoryTopicDetail = lazy(() => import('../pages/contest/SubmitHistoryTopicDetail'));
const BroadcastReceiver = lazy(() => import('../pages/contest/BroadcastReceiver'));
const BroadcastSender = lazy(() => import('../pages/contest/BroadcastSender'));
const EditorMonitor = lazy(() => import('../pages/admin/EditorMonitor'));

const MainRouter: AuthRouterObject[] = [
    {
        path: '/',
        element: <Navigate to='/login' />
    },
    {
        path: '/',
        element: <MiniLayout />,
        children: [
            {
                path: 'login',
                element: <Login />
            },
            {
                path: 'register',
                element: <Register />
            },
            {
                path: '/',
                element: <AuthRouter><MainLayout /></AuthRouter>,
                children: [
                    {
                        path: 'contest',
                        element: <Contest />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'contest/topics',
                        element: <Topics />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'contest/topics/topicDetail',
                        element: <TopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'submitHistoryTopicDetail',
                        element: <SubmitHistoryTopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'myContest',
                        element: <MyContest />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'myContest/topics',
                        element: <Topics />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'topicDetail',
                        element: <TopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'myContest/topics/topicDetail',
                        element: <TopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'myCourse/topics/topicDetail',
                        element: <TopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'rank',
                        element: <Rank />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'faqs',
                        element: <FAQs />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'submitHistory',
                        element: <SubmitHistory />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'submitHistory/topicDetail',
                        element: <TopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'submitHistory/runtimeInfo',
                        element: <RuntimeInfo />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'summaryToday',
                        element: <SummaryToday />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: '/knowledgeShare',
                        element: <KnowledgeShare />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: '/knowledgeShare/knowledgeShareDetail',
                        element: <KnowledgeShareDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'noPermission',
                        element: <NoPermission />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'setProfile',
                        element: <ResetPassword />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'contestRank',
                        element: <ContestRank />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'contestRank/topicDetail',
                        element: <TopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'contestRank/showSourceCode',
                        element: <ShowSourceCode />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'topics/showSourceCode',
                        element: <ShowSourceCode />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'showSourceCode',
                        element: <ShowSourceCode />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'contestRank/showSourceCode/topicDetail',
                        element: <TopicDetail />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'onlineHelp',
                        element: <OnlineDiscuss />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'classMgmt',
                        element: <ClassMgmt />,
                        auth: ["admin"]
                    },
                    {
                        path: 'schoolMgmt',
                        element: <SchoolMgmt />,
                        auth: ['admin'],
                    },
                    {
                        path: '/userList',
                        element: <UserList />,
                        auth: ['admin', 'teacher'],
                    },
                    {
                        path: 'addUserProfile',
                        element: <AddUserProfile />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'editUserProfile',
                        element: <EditUserProfile />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'portraitMgmt',
                        element: <PortraitMgmt />,
                        auth: ['admin']
                    },
                    {
                        path: 'topicsMgmt',
                        element: <TopicsMgmt />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'topicSetMgmt',
                        element: <TopicSetMgmt />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'myContestMgmt',
                        element: <MyContestMgmt />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'topicEdit',
                        element: <TopicEdit />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'topicAdd',
                        element: <TopicAdd />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'createContest',
                        element: <CreateContests />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'editContest',
                        element: <EditContests />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'studentPerformaceList',
                        element: <StudentPerformaceList />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'messageMgmt',
                        element: <MessageMgmt />,
                        auth: ['admin'],
                    },
                    {
                        path: 'loginList',
                        element: <LoginList />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'userInfo',
                        element: <UserInfo />,
                        auth: ['admin']
                    },
                    {
                        path: 'checkInMgmt',
                        element: <CheckInMgmt />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'checkInList',
                        element: <CheckInList />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'userSubmitDetail',
                        element: <UserSubmitDetail />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'favoritesList',
                        element: <FavoritesList />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'studentList',
                        element: <StudentList />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'snapshot',
                        element: <Snapshot />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'snapshotDetail',
                        element: <SnapshotDetail />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'activateVIP',
                        element: <ActivateVIP />,
                        auth: ['admin', 'teacher', 'student']
                    },
                    {
                        path: 'batchImportUser',
                        element: <BatchImportUser />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'userContestStatus',
                        element: <UserContestStatus />,
                        auth: ['admin', 'teacher', 'student']
                    },
                    {
                        path: 'userContestStatus/showSourceCode',
                        element: <ShowSourceCode />,
                        auth: ["admin", "student", "teacher"]
                    },
                    {
                        path: 'judgeMgmt',
                        element: <JudgeMgmt />,
                        auth: ['admin'],
                    },
                    {
                        path: 'choiceTopicsMgmt',
                        element: <ChoiceTopicsMgmt />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'createChoiceTopics',
                        element: <CreateChoiceTopics />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'editChoiceTopics',
                        element: <EditChoiceTopics />,
                        auth: ['admin', 'teacher']
                    },
                    {
                        path: 'evaluationCenter',
                        element: <EvaluationCenter />,
                        auth: ['admin', 'teacher', 'student']
                    },
                    {
                        path: 'evaluation',
                        element: <Evaluation />,
                        auth: ['admin', 'teacher', 'student']
                    },
                    {
                        path: 'workplace',
                        element: <Workplace />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'evaluationMgmt',
                        element: <EvaluationMgmt />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'platformMgmt',
                        element: <PlatformMgmt />,
                        auth: ['admin']
                    },
                    {
                        path: 'createCompletion',
                        element: <CreateCompletion />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'editCompletion',
                        element: <EditCompletion />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'evaluationSummary',
                        element: <EvaluationSummary />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'evaluationDetail',
                        element: <EvaluationDetail />,
                        auth: ['teacher', 'admin']

                    },
                    {
                        path: 'activityEntrance',
                        element: <ActivityEntrance />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'activityEntrance/activityEntranceDetail',
                        element: <ActivityEntranceDetail />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'productMgmt',
                        element: <ProductMgmt />,
                        auth: ['admin']
                    },
                    {
                        path: 'blocklyTopicAdd',
                        element: <BlocklyTopicAdd />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'blocklyTopicEdit',
                        element: <BlocklyTopicEdit />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'contest/topics/scratchTopicDetail',
                        element: <ScratchTopicDetail />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'myCourse/lessonPlan/topics/scratchTopicDetail',
                        element: <ScratchTopicDetail />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'scratchSubmit',
                        element: <ScratchSubmit />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'myCourse',
                        element: <MyCourse />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'editCourse',
                        element: <EditCourse />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'createCourse',
                        element: <CreateCourse />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'myCourse/lessonPlan',
                        element: <LessonPlan />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'myCourse/topics',
                        element: <Topics />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'myCourse/lessonPlan/topics',
                        element: <Topics />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'myCourse/lessonPlan/topics/topicDetail',
                        element: <TopicDetail />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'pdfTopicDetail',
                        element: <PdfTopicDetail />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'exportTopicList',
                        element: <ExportTopicList />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'memberAnswers',
                        element: <MemberAnswers />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'flowEdit',
                        element: <FlowEdit />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'broadcastReceiver',
                        element: <BroadcastReceiver />,
                        auth: ['teacher', 'admin', 'student']
                    },
                    {
                        path: 'broadcastSender',
                        element: <BroadcastSender />,
                        auth: ['teacher', 'admin']
                    },
                    {
                        path: 'editorMonitor',
                        element: <EditorMonitor />,
                        auth: ['teacher', 'admin']
                    }
                ]
            }
        ]
    }

]
export default MainRouter;