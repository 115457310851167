import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { createRoot } from 'react-dom/client';
import App from './App';
import { HashRouter } from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import { Provider } from 'react-redux';
import store from './store/store';
import { Suspense } from 'react';
import { SocketProvider } from './contexts/SocketContext';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Suspense>
    <HashRouter basename='/'>
      <Provider store={store}>
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]} >
          <ScrollTop>
            <SocketProvider>
              <App />
            </SocketProvider>
          </ScrollTop>
        </StyleProvider>
      </Provider>
    </HashRouter>
  </Suspense>
);
