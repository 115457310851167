import React from 'react';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
const NoPermission = () => {
    const navigate = useNavigate()
    return (
        <div style={{ padding: '15%', textAlign: 'center' }}>
            <Card style={{ border: '1px solid #1890ff', textAlign: 'center', margin: '20px' }}>
                <h1>你似乎来到了没有编程知识存在的荒原</h1>
            </Card>
            <Button type='primary' onClick={() => { navigate(-2) }}>返回上一页</Button>
        </div>
    );
}
export default NoPermission;